import React from 'react';
import './Start.scss'; // Assuming you might want to style it separately.

const Start = () => {
  return (
    <div className='start-container'>
      <h1>Welcome to our Website!</h1>
      <p>This is the starting point of your journey with us.</p>
      <button>Get Started</button>
    </div>
  );
};

export default Start;
