import React from 'react';
import ReactDOM from 'react-dom';
import Start from './features/start/Start';

const App: React.FC = () => {
  return (
    <div className='App'>
      <Start />
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));
